<template>
  <a-spin :spinning="isPageLoading">
    <a-alert type="info"
             show-icon>
      <template #message>
        部分配置需登录企业微信管理后台，也可添加
        <span class="py-0 px-4 text-admin-primary cursor-pointer"
              @click="codeVisible = true">专属客服
        </span>
        协助您完成配置。
      </template>
    </a-alert>

    <a-modal v-model:visible="codeVisible"
             class="code-modal"
             title="添加专属客服"
             :footer="null"
             width="400px"
             centered>
      <p>一对一为您答疑解惑</p>
      <img class="code-modal__img"
           src="@/assets/EduSohoCustomerServiceCode.png" />
      <p class="color-primary">立即扫码添加客服</p>
    </a-modal>

    <div class="mt-32">
      <a-row :gutter="24">
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_customer_contact'})">
            <svg-icon class="system-item__icon"
                      type="iconkehulianxi" />
            <div>
              <p class="font-medium mb-24">客户联系</p>
              <p class="text-color-999 text-14">通过客户联系配置，企业可统一管理这些员工的客户。</p>
            </div>
            <span class="absolute top-16 right-16 font-medium text-14 text-admin-primary"
                  :class="{'text-danger': !config.setClientSecret?.completePercent}">{{ config.setClientSecret?.completePercent ? "已配置": "待配置" }}</span>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_official_account'})">
            <img class="system-item__icon"
                 src="@/assets/system_setting_public.png"
                 alt="公众号图标">
            <div>
              <p class="font-medium mb-24">公众号</p>
              <p class="text-color-999 text-14">当客户在访问商机雷达、支付订单等场景时，系统能够获取客户信息。</p>
            </div>
            <span v-if="config.setWechatConf?.completePercent === 100"
                  class="absolute top-16 right-16 font-medium text-14 text-admin-primary">已授权</span>
            <span v-else
                  class="absolute top-16 right-16 font-medium text-14 text-danger">{{ config.setWechatConf?.completePercent === 50 ? "配置完成 50%": "未授权" }}</span>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_corp_payment'})">
            <svg-icon class="system-item__icon"
                      type="iconqiyezhifu" />
            <div>
              <p class="font-medium mb-24">企业支付</p>
              <p class="text-color-999 text-14">配置企业微信支付后，客户可以直接通过微信购买商品，资金进入企业账户。</p>
            </div>
            <span class="absolute top-16 right-16 font-medium text-14 text-admin-primary"
                  :class="{'text-danger': config.setWechatPay?.status !== 'open'}">{{ config.setWechatPay?.status === 'open' ? "已开启": "待开启" }}</span>
          </div>
        </a-col>
      </a-row>

      <a-row class="mv24"
             :gutter="24">
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_chat_toolbar'})">
            <svg-icon class="system-item__icon"
                      type="iconliaotiangongjulan" />
            <div>
              <p class="font-medium mb-24">聊天工具栏</p>
              <p class="text-color-999 text-14">在企业微信聊天工具栏配置快捷回复、客户画像、商品订单、工作台等菜单。</p>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_chat_record'})">
            <svg-icon class="system-item__icon"
                      type="iconhuihuacundang01" />
            <div>
              <p class="text-bold"
                 :style="{marginBottom: '24px'}">会话存档</p>
              <p class="text-color-999 text-14">获取成员与客户的会话内容，让企业实现对客户服务质量、效率和客户满意度的监控</p>
            </div>
            <span class="absolute top-16 right-16 font-medium text-14 text-admin-primary"
                  :class="{'text-danger': !config.setConversationArchive?.completePercent}">{{ config.setConversationArchive?.completePercent ? "已开启": "未开启" }}</span>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_online_school'})">
            <img class="system-item__icon"
                 src="@/assets/system_setting_es.png"
                 alt="网校图标">
            <div>
              <p class="font-medium mb-24">EduSoho网校</p>
              <p class="text-color-999 text-14">可以在销客助手平台售卖网校的商品，如课程和班级。</p>
            </div>
            <span class="absolute top-16 right-16 font-medium text-14 text-admin-primary"
                  :class="{'text-danger': config.setES?.status !== 'open'}">{{ config.setES?.status === 'open' ? "已关联": "待关联" }}</span>
          </div>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="8">
          <div class="system-item"
               @click="$router.push({name: 'system_setting_live'})">
            <svg-icon class="system-item__icon"
                      type="iconzhibo" />
            <div>
              <p class="text-bold"
                 :style="{marginBottom: '24px'}">EduSoho直播</p>
              <p class="text-color-999 text-14">EduSoho直播平台，提供教学、营销能力。</p>
            </div>
            <div class="absolute top-16 right-16 py-4 px-8 font-medium text-14 text-admin-primary text-admin-primary border-radius"
                 :class="{'text-danger': lastHours <= 500 || config.setLive?.status !== 'open'}"
                 style="height: unset">
              {{ config.setLive?.status === 'open' ? `${lastHours}小时` : "未开启" }}
            </div>
          </div>
        </a-col>

      </a-row>
    </div>
  </a-spin>

</template>

<script>
import _ from "lodash";
import { computed, defineComponent, reactive, ref } from "vue";
import SvgIcon from "@/components/SvgIcon";

import corpSettingApi from "@/service/api/corpSetting";

export default defineComponent({
  name: "SystemSettingIndex",

  components: {
    SvgIcon,
  },

  setup() {
    // TODO:  配置页在后台路由中添加name，方便动态面包屑的组装
    const HOUR_UNIT = 3600;
    const isPageLoading = ref(true);

    const codeVisible = ref(false);

    const config = reactive({});

    async function getConfigurations() {
      const res = await corpSettingApi.getConfigurations();
      _.assign(config, res);
      isPageLoading.value = false;
    }

    const lastHours = computed(() => {
      const seconds = config.setLive?.remainSeconds;
      if (seconds) {
        const hours = (seconds / HOUR_UNIT).toFixed();
        return hours;
      }

      return 0;
    });

    getConfigurations();

    return {
      isPageLoading,
      codeVisible,
      config,

      HOUR_UNIT,
      lastHours,
    };
  },
});
</script>

<style lang='less' scoped>
.ant-alert-info {
  width: 55%;
  background: #e9f6ff;
  .border(@color: @color-primary);
  .border-radius();

  :deep(.ant-alert-message) {
    color: @gray-dark;
  }
}
:deep(.ant-col) > div {
  padding: @spacing-8x @spacing-4x;

  .border-radius();
  background-color: @bg-normal;

  &:hover {
    .shadow-small();
  }
}

.system-item {
  position: relative;
  display: flex;
  align-items: center;
  .text-16();
  user-select: none;
  cursor: pointer;

  & > div {
    height: 124px;
  }

  &__icon {
    margin-right: 24px;
    width: 124px;
    height: 124px;
    font-size: 124px;
  }
}
</style>